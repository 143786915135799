<template>
<div class="now-playing">
	<section class="estrenos dark">
		<div class="container" v-if="nowPlaying.data && nowPlaying.data.length > 0 && !isLoading">
			<div class="section-header">
				<h2 class="section-title">Estrenos de la Semana</h2>
				<div class="swiper-navigation">
					<div class="swiper-button-prev" @click="prevSlide($refs.trailerSwiper)"></div>
					<div class="swiper-button-next" @click="nextSlide($refs.trailerSwiper)"></div>
				</div> 
			</div>    
			<swiper class="row" ref="trailerSwiper" :options="TrailersSwiperOptions">
				<swiper-slide class="col l3 s6" v-for="movie in nowPlaying.data" :key="movie.id">
					<MovieItem :movie="movie" :showPlay="false" :showTitle="false" :showDateTag="false" />
				</swiper-slide>
			</swiper>
		</div>

		<div class="container" v-else>
			<div class="section-header">
				<h2 class="section-title">Estrenos de la Semana</h2>
			</div>
			<swiper class="row" ref="trailerSwiper" :options="TrailersSwiperOptions">
				<swiper-slide class="col l3 s6 m4" v-for="(n,index) in 4" :key="index">
					<MovieItem :showPlay="false" :showTitle="false" :showDateTag="false" />
				</swiper-slide>
			</swiper>
		</div>
	</section>
	<router-link :to="/peliculas/ + featuredMovie.slug">
		<Coverpage :single="featuredMovie" />
	</router-link>
	<section name="proximamente">
        <div class="container">
            <div class="section-header">
                <h2 class="section-title">Estrenos anteriores</h2>
            </div>
            <div class="section-content" v-if="isLoading">
                <div class="col l2 m3 s4" v-for="(n,index) in 12" :key="index">
                    <MovieItem :showPlay="false" :showDateTag="true" :showTitle="true" />
                </div>
            </div> 
            <div class="section-content" v-else-if="previousReleases.data && previousReleases.data.length > 0">   
                <div class="col l2 m3 s4" v-for="movie in previousReleases.data" :key="movie.id">
                    <MovieItem :movie="movie" :showPlay="false" :showDateTag="true" :showTitle="true" />
                </div>
                <Spinner v-if="isLoadingMore"/>
                <div class="bottom-container" v-if="loadMoreStatus && !isLoadingMore"><a @click="loadMore()" class="load-more">Cargar Más</a></div>
            </div>
            <div class="section-content" v-else>
                <NotFound/>
            </div>
        </div>
    </section>    
</div>
</template>

<script>
import MovieItem from '@/components/MovieItem.vue'
import Coverpage from '@/components/Coverpage.vue'
import NewsItem from '@/components/NewsItem.vue'
import Spinner from '@/components/Spinner.vue'

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

import 'swiper/swiper-bundle.css'

export default {
	name: 'Estrenos',
	components:{
			MovieItem,
			NewsItem,
			Coverpage,
			Swiper,
			SwiperSlide,
			Spinner
	},
	data(){
		return {
			isLoading: true,
			nowPlaying: [],
			featuredMovie: [],
			previousReleases: [],
			page: 1,
            loadMoreStatus: false,
			isLoadingMore: false,
			swiperOptions: {
				slidesPerView: 3,
				spaceBetween: 8,
				grabCursor: true,
				simulateTouch: true,
			},
			TrailersSwiperOptions: {
				slidesPerView: 2,
				spaceBetween: 8,
				freeMode: true,
				freeMode: {
					minimunVelocity: 0.03,
					momentum: true
				},
				breakpoints: {
					768: {
						slidesPerView: 4,
						spaceBetween: 20
					}
				}
			}
		}
	},
	directives: {
			swiper: directive
	},
	mounted (){	
		(async () => {
			const category = this.$route.params.category

            this.setHeader('page', {
                titulo: 'Estrenos'
            })

			this.isLoading = true

			// get thu
			const thisThu = this.getThu('this')

			// now playing
			this.nowPlaying = await this.getItems('peliculas', 4, 'filter[fecha_local][lte]='+thisThu+'&sort=-fecha_local')

			// featured movies
			this.featuredMovie = this.nowPlaying.data[0]

			this.featuredMovie.titulo = (this.featuredMovie.titulo_local) ? this.featuredMovie.titulo_local : this.featuredMovie.titulo_original

			// previous releases
			this.previousReleases = await this.getItems('peliculas', 12, 'filter[fecha_local][lt]='+thisThu+'&sort=-fecha_local,-visualizaciones&meta=total_count,result_count,filter_count')

			if( (this.previousReleases.meta.filter_count > this.previousReleases.data.length) || (this.previousReleases.meta.filter_count > this.previousReleases.data.length) ){
                this.page++
                this.loadMoreStatus = true
            }else{
                this.loadMoreStatus = false
            }
			
			this.isLoading = false
		})()
	},
	methods: {
		loadMore: async function(){
            this.isLoadingMore = true
            await this.loadItems()
            this.isLoadingMore = false
        },
		loadItems: async function(){
            // get thu
			const thisThu = this.getThu('this')

            //get previous movies
            let previousMovies = this.previousReleases.data

            let filter = 'page='+this.page+'&filter[estado][eq]=publicado&filter[fecha_local][lt]='+thisThu+'&sort=-fecha_local,-visualizaciones&meta=total_count,result_count,filter_count';

            
            this.movies = await this.getItems('peliculas', 12, filter)

            this.previousReleases.data = previousMovies.concat(this.movies.data)
                
            if( (this.previousReleases.meta.filter_count > this.previousReleases.data.length) || (this.previousReleases.meta.filter_count > this.previousReleases.data.length) ){
                this.page++
                this.loadMoreStatus = true
            }else{
                this.loadMoreStatus = false
            }
            
            this.isLoading = false
        }
	}
}
</script>

<style scoped>
	section.dark:first-child{
		margin-top: -16px;
	}

	@media(max-width: 1024px){
		.m4:last-child{
			display: none !important;
		}
	}

	@media(max-width: 768px){
		.m4:last-child{
			display: initial !important;
		}
	}
</style>